import { SET_WHATSNEXT } from "../actionTypes";

const initialState =
  "We’ll review the information you have provided before we next meet.\n\nIf you don’t already have an appointment, we will be in touch to organise the best time for an appointment. If you don’t hear from us in the next couple of days, please give us a call.\n\nWhat to bring to our appointment?\n\n - Identification – drivers licence, passport, birth certificate \n - Details of your assets e.g. property, bank accounts, superannuation and investments \n - Existing estate planning documents – Will & Power of Attorneys";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WHATSNEXT: {
      return action.payload;
    }
    default:
      return state;
  }
};
