import { SET_IS_LOADING } from '../actionTypes';

const initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING: {
      return action.payload;
    }
    default:
      return state;
  }
};
