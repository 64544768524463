import {
  SET_MAIN_LOGO,
  SET_FAVICON,
  SET_THEME,
  SET_DOMAIN,
  SET_TERMS,
  SET_POLICY,
  SET_WEBSITE,
  SET_BLURB,
  SET_COMPLETION,
  SET_WHATSNEXT_HD,
  SET_WHATSNEXT,
  SET_THINKABOUT_HD,
  SET_THINKABOUT,
  SET_UPDATEINFO_HD,
  SET_UPDATEINFO,
  SET_COMPANYNAME,
  SET_ADDRESS1,
  SET_ADDRESS2,
  SET_ADDRESS3,
  SET_COUNTY,
  SET_POSTCODE,
  SET_COUNTRY,
  SET_TELEPHONE,
  SET_EMAIL,
  GET_ACCOUNT_DETAILS,
  UPDATE_ACCOUNT_DETAILS,
  UPDATING_ACCOUNT_DETAILS,
  UPDATING_LOGO,
  SET_IS_LOADING,
  SET_INCLUDEASSETS
} from "../actionTypes";

export const setIsLoading = bool => ({
  type: SET_IS_LOADING,
  payload: bool
});

export const setMainLogo = file => ({
  type: SET_MAIN_LOGO,
  payload: file
});

export const setFavIcon = file => ({
  type: SET_FAVICON,
  payload: file
});

export const setTheme = (type, color) => ({
  type: SET_THEME,
  payload: {
    type,
    color
  }
});

export const setDomain = domain => ({
  type: SET_DOMAIN,
  payload: domain
});

export const setTerms = terms => ({
  type: SET_TERMS,
  payload: terms
});

export const setPolicy = policy => ({
  type: SET_POLICY,
  payload: policy
});

export const setWebsite = website => ({
  type: SET_WEBSITE,
  payload: website
});

export const setBlurb = blurb => ({
  type: SET_BLURB,
  payload: blurb
});

export const setIncludeAssets = includeAssets => ({
  type: SET_INCLUDEASSETS,
  payload:includeAssets
});

export const setCompletion = completion => ({
  type: SET_COMPLETION,
  payload: completion
});

export const setWhatsNextHd = whatsnexthd => ({
  type: SET_WHATSNEXT_HD,
  payload: whatsnexthd
});

export const setWhatsNext = whatsnext => ({
  type: SET_WHATSNEXT,
  payload: whatsnext
});

export const setThinkAboutHd = thinkabouthd => ({
  type: SET_THINKABOUT_HD,
  payload: thinkabouthd
});

export const setThinkAbout = thinkabout => ({
  type: SET_THINKABOUT,
  payload: thinkabout
});

export const setUpdateInfoHd = updateinfohd => ({
  type: SET_UPDATEINFO_HD,
  payload: updateinfohd
});

export const setUpdateInfo = updateinfo => ({
  type: SET_UPDATEINFO,
  payload: updateinfo
});

export const setCompanyName = companyname => ({
  type: SET_COMPANYNAME,
  payload: companyname
});

export const setAddress1 = address1 => ({
  type: SET_ADDRESS1,
  payload: address1
});

export const setAddress2 = address2 => ({
  type: SET_ADDRESS2,
  payload: address2
});

export const setAddress3 = address3 => ({
  type: SET_ADDRESS3,
  payload: address3
});

export const setCounty = county => ({
  type: SET_COUNTY,
  payload: county
});

export const setPostcode = postcode => ({
  type: SET_POSTCODE,
  payload: postcode
});

export const setCountry = country => ({
  type: SET_COUNTRY,
  payload: country
});

export const setTelephone = telephone => ({
  type: SET_TELEPHONE,
  payload: telephone
});

export const setEmail = email => ({
  type: SET_EMAIL,
  payload: email
});

export const getAccountDetails = accountDetails => ({
  type: GET_ACCOUNT_DETAILS,
  payload: accountDetails
});

export const updateAccountDetails = accountDetails => ({
  type: UPDATE_ACCOUNT_DETAILS,
  payload: accountDetails
});

export const updatingAccountDetails = accountDetails => ({
  type: UPDATING_ACCOUNT_DETAILS,
  payload: null
});

export const updatingLogo = logo => ({
  type: UPDATING_LOGO,
  payload: logo
});