import { SET_COMPLETION } from "../actionTypes";

const initialState =
  "Thank you for providing us with this information, this really helps to minimise time-consuming information gathering and maximise the value of any discussions we have with you. If you are a new client we will be in touch to discuss your situation and our process, and to organise the best time for an appointment.";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPLETION: {
      if (action.payload === "") {
        return initialState;
      } else {
        return action.payload;
      }
    }
    default:
      return state;
  }
};
