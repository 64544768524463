import { SET_FAVICON } from "../actionTypes";

const initialState = "";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FAVICON: {
      return action.payload;
    }
    default:
      return state;
  }
};
