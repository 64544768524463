import { SET_INCLUDEASSETS } from "../actionTypes";

const initialState = true;

export default (state = initialState, action) => {

  switch (action.type) {
    case SET_INCLUDEASSETS: {
       return action.payload === undefined? true: action.payload ;
   }
    default:
      return state;
  }
};
