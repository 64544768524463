export default {
  sharedOAuthConfig: {
    grant_type: "client_credentials",
    scope: "all:access"
  },
  local: {
    aus: {},
    nzl: {},
    gbr: {
      address: 'http://localhost:3000',
      callback: 'http://localhost:3000/callback',
      // api.myplan.app
      authenticationKeys: {
        client_id: process.env.REACT_APP_LOCAL_CLIENT_ID
       },
      oAuthConfig: {
        // frontend.myplan.app
        token_url: 'https://arken-dev.eu.auth0.com/oauth/token',
        audience: 'https://auth.api.acquire.uk.arken-dev.com',
        domain: 'arken-dev.eu.auth0.com',
      },
      apiUrls: {
        account: `${process.env.REACT_APP_LOCAL_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_LOCAL_URL}/v1/gbr/Client`,
        addressFinder:
          'https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=',
        addressFinderPxid:
          'https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid=',
      },
    },
    rsa: {},
  },
  development: {
    aus: {},
    nzl: {},
    gbr: {
      address: 'https://mngapp.devt.arken.legal',
      callback: 'https://mngapp.devt.arken.legal/callback',
      // api.myplan.app
      authenticationKeys: {
        client_id: process.env.REACT_APP_DEV_AUTH_KEY
      },
      oAuthConfig: {
        // frontend.myplan.app
        token_url: 'https://arken-dev.eu.auth0.com/oauth/token',
        audience: 'https://auth.api.acquire.uk.arken-dev.com',
        domain: 'arken-dev.eu.auth0.com',
      },
      apiUrls: {
        account: `${process.env.REACT_APP_DEV_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_DEV_URL}/v1/gbr/Client`,
        addressFinder:
          'https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=',
        addressFinderPxid:
          'https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid=',
      },
    },
    rsa: {},
  },
  testing: {
    aus: {},
    nzl: {},
    gbr: {
      address: "https://mngacq.test.arken.legal",
      callback: "https://mngacq.test.arken.legal/callback",
      // api.myplan.app
      authenticationKeys: {
        client_id: process.env.REACT_APP_TEST_AUTH_KEY
      },
      oAuthConfig: {
        // frontend.myplan.app
        token_url: "https://pre-arkenlegal.eu.auth0.com/oauth/token",
        audience: "https://pre-auth-myplan-uk-eu2.api.arken.legal",
        domain: "pre-arkenlegal.eu.auth0.com"
      },
      apiUrls: {
        account: `${process.env.REACT_APP_TEST_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_TEST_URL}/v1/gbr/Client`,
        addressFinder:
          "https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=",
        addressFinderPxid:
          "https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid="
      }
    },
    rsa: {}
  },
  preprod: {
    aus: {},
    nzl: {},
    gbr: {
      address: "https://mngacq.stage.arken.legal",
      callback: "https://mngacq.stage.arken.legal/callback",
      // api.myplan.app
      authenticationKeys: {
        client_id: process.env.REACT_APP_PRE_AUTH_KEY
      },
      oAuthConfig: {
        // frontend.myplan.app
        token_url: "https://pre-arkenlegal.eu.auth0.com/oauth/token",
        audience: "https://pre-auth-myplan-uk-eu2.api.arken.legal",
        domain: "pre-arkenlegal.eu.auth0.com"
      },
      apiUrls: {
        account: `${process.env.REACT_APP_PRE_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_PRE_URL}/v1/gbr/Client`,
        addressFinder:
          "https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=",
        addressFinderPxid:
          "https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid="
      }
    },
    rsa: {}
  },
  production: {
    aus: {},
    nzl: {},
    gbr: {
      address: "https://uk.manage.acquire.arken.legal",
      callback: "https://uk.manage.acquire.arken.legal/callback",
      // api.myplan.app
      authenticationKeys: {
        client_id: process.env.REACT_APP_AUTH_KEY
      },
      oAuthConfig: {
        // frontend.myplan.app
        token_url: "https://arkenlegal.eu.auth0.com/oauth/token",
        audience: "https://auth-myplan-uk-eu2.api.arken.legal",
        domain: "arkenlegal.eu.auth0.com"
      },
      apiUrls: {
        account: `${process.env.REACT_APP_URL}/v1/gbr/Account`,
        client: `${process.env.REACT_APP_URL}/v1/gbr/Client`,
        addressFinder:
          "https://api.addressfinder.io/api/nz/address?key=HQ96UDLJEBN48GXMRPCF&format=json&strict=2&q=",
        addressFinderPxid:
          "https://api.addressfinder.io/api/nz/address/info?key=HQ96UDLJEBN48GXMRPCF&format=json&pxid="
      }
    },
    rsa: {}
  },
  currencySymbols: {
    aus: "$",
    nzl: "$",
    gbr: "£",
    rsa: "R"
  },
  location: "gbr",
  country: "United Kingdom"
};
