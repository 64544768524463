import config from "../api/config";

export const getDomain = state => state.domain;
export const getTerms = state => state.terms;
export const getPolicy = state => state.policy;
export const getWebsite = state => state.website;
export const getBlurb = state => state.blurb;
export const getIncludeAssets = state => state.includeAssets;
export const getCompletion = state => state.completion;
export const getWhatsNextHd = state => state.whatsnexthd;
export const getWhatsNext = state => state.whatsnext;
export const getThinkAboutHd = state => state.thinkabouthd;
export const getThinkAbout = state => state.thinkabout;
export const getUpdateInfoHd = state => state.updateinfohd;
export const getUpdateInfo = state => state.updateinfo;
export const getCompanyName = state => state.companyname;
export const getAddress1 = state => state.address1;
export const getAddress2 = state => state.address2;
export const getAddress3 = state => state.address3;
export const getCounty = state => state.county;
export const getPostcode = state => state.postcode;
export const getTelephone = state => state.telephone;
export const getEmail = state => state.email;
export const getTheme = state => state.theme;
export const getMainLogo = state => state.mainLogo;
export const getFavIcon = state => state.favIcon;
export const getExtensionData = state =>  state.accountDetails.data.extensionData;
export const getAccountDetails = state => state.accountDetails;
export const getisLoading = state => state.isLoading;
export const getApiConfig = state => ({
  ...config[process.env.REACT_APP_ENVIRONMENT][state.country],
  oAuthConfig: {
    ...config[process.env.REACT_APP_ENVIRONMENT][state.country].oAuthConfig,
    ...config.sharedOAuthConfig
  }
});
