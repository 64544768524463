import React, { Fragment } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { MenuList, MenuItem } from "@material-ui/core";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { CompanyDetails, Blurb, CompanyStyle } from "./PageIndex";
import Button from "@material-ui/core/Button";
import * as actions from "./reducers/actions";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1
  },
  colorCardWrapper: {
    margin: "0 10px 10px 10px",
    border: "1px dotted #ddd",
    textAlign: "center",
    padding: "10px"
  },
  colorCard: {
    cursor: "pointer",
    width: "50px",
    padding: "0px",
    height: "50px",
    display: "inline-block"
  },
  colorCardContent: {
    width: "50px",
    height: "50px",
    margin: 0,
    padding: 0
  },
  colorCardNotes: {
    fontSize: "12px"
  },
  companyLogoContiner: {
    flexGrow: 1,
    display: "flex"
  },
  companyLogo: {
    height: "40px",
    width: "120px"
  },
  toolbar: {
    paddingTop: 35,
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#fff",
    color: "#636c72"
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    paddingLeft: 24,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#fff",
    color: "#636c72"
  },

  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  preview: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500
  },
  previewContainer: {
    padding: 0
  },
  previewContent: {
    flexGrow: 1,
    height: "400px",
    overflow: "auto"
  },
  paperTitle: {
    marginBottom: "30px"
  },
  fixedHeight: {
    height: 240
  },
  sectionTitleWrapper: {
    marginTop: "20px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
      marginBottom: "20px"
    }
  },
  sectionTitle: {
    padding: "10px",
    textAlign: "center",
    height: "100%",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#636c72",
    [theme.breakpoints.up("sm")]: {
      borderRight: "4px solid #dad3d3",
      textAlign: "right"
    }
  },
  sectionContentWrapper: {
    marginBottom: "20px",
    textAlign: "center"
  },
  sectionContent: {
    [theme.breakpoints.up("sm")]: {
      padding: "10px"
    }
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  progress: {
    color: "#fff"
  }
}));

const img = {
  display: "block",
  width: "100%",
  height: "100%"
};

const MenuItems = ({ isLoading, updatingAccountDetails }) => {
  const classes = useStyles();

  const handleSetupNow = () => {
    updatingAccountDetails();
  };

  const currentPath = window.location.pathname;
  const pathComponents = currentPath.split("/").filter(Boolean);
  const [country, accountId] = pathComponents;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <img
          src={`${process.env.PUBLIC_URL}/arken-logo.png`}
          className={classes.companyLogo}
          alt="Arken Logo"
        />
      </AppBar>
      <Toolbar className={classes.toolbar}>
        <Fragment>
          <Router>
            <MenuList>
              <MenuItem
                component={Link}
                to={`/${country}/${accountId}/CompanyDetails`}
              >
                Company Details
              </MenuItem>
              <MenuItem
                component={Link}
                to={`/${country}/${accountId}/CompanyStyle`}
              >
                Styling & Domain
              </MenuItem>
              <MenuItem component={Link} to={`/${country}/${accountId}/Blurb`}>
                Blurb
              </MenuItem>
              <Button
                variant="contained"
                color="secondary"
                align="center"
                className={classes.setupNowButton}
                onClick={handleSetupNow}
                disabled={isLoading}
              >
                Setup now
              </Button>
            </MenuList>

            <Switch>
              <Route
                exact
                path={[
                  `/${country}/${accountId}`,
                  `/${country}/${accountId}/CompanyDetails`
                ]}
                render={() => (
                  <main>
                    <div className={classes.container} />
                    <CompanyDetails />
                  </main>
                )}
              />
              <Route
                exact
                path={`/${country}/${accountId}/CompanyStyle`}
                render={() => (
                  <main>
                    <div className={classes.container} />
                    <CompanyStyle />
                  </main>
                )}
              />
              <Route
                exact
                path={`/${country}/${accountId}/Blurb`}
                render={() => (
                  <main>
                    <div className={classes.container} />
                    <Blurb />
                  </main>
                )}
              />
            </Switch>
          </Router>
        </Fragment>
      </Toolbar>
    </div>
  );
};

const mapStateToProps = state => ({
  accountDetails: state.accountDetails,
  isLoading: state.isLoading
});

const mapDispatchToProps = {
  updateAccountDetails: actions.updateAccountDetails,
  updatingAccountDetails: actions.updatingAccountDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuItems);
