import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import { SketchPicker } from "react-color";

import reactCSS from "reactcss";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputAdornment from "@material-ui/core/InputAdornment";

import * as actions from "./reducers/actions";
import { Switch,FormGroup,FormControlLabel } from "@material-ui/core";


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1
  },
  colorCardWrapper: {
    margin: "0 10px 10px 10px",
    border: "1px dotted #ddd",
    textAlign: "center",
    padding: "10px"
  },
  colorCard: {
    cursor: "pointer",
    width: "50px",
    padding: "0px",
    height: "50px",
    display: "inline-block"
  },
  colorCardContent: {
    width: "50px",
    height: "50px",
    margin: 0,
    padding: 0
  },
  colorCardNotes: {
    fontSize: "12px"
  },
  companyLogoContiner: {
    flexGrow: 1,
    display: "flex"
  },
  companyLogo: {
    height: "30px"
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#fff",
    color: "#636c72"
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: "#636c72"
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  preview: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500
  },
  previewContainer: {
    padding: 0
  },
  previewContent: {
    flexGrow: 1,
    height: "400px",
    overflow: "auto"
  },
  paperTitle: {
    marginBottom: "30px"
  },
  fixedHeight: {
    height: 240
  },
  sectionTitleWrapper: {
    marginTop: "20px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
      marginBottom: "20px"
    }
  },
  sectionTitle: {
    padding: "10px",
    textAlign: "center",
    height: "100%",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#636c72",
    [theme.breakpoints.up("sm")]: {
      borderRight: "4px solid #dad3d3",
      textAlign: "right"
    }
  },
  sectionContentWrapper: {
    marginBottom: "20px",
    textAlign: "center"
  },
  sectionContent: {
    [theme.breakpoints.up("sm")]: {
      padding: "10px"
    }
  },
  dropZoneDragArea: {
    border: "1px dotted #ddd",
    padding: "20px",
    textAlign: "center"
  },
  dropZoneNotes: {
    fontSize: "12px"
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  setupNowButton: {
    width: "120px"
  },
  progress: {
    color: "#fff"
  }
}));

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box"
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden"
};

const img = {
  display: "block",
  width: "auto",
  height: "100%"
};

const CompanyStyle = ({
  logo,
  theme,
  domain,
  terms,
  policy,
  website,
  setMainLogo,
  // setFavIcon,
  setTheme,
  setDomain,
  setTerms,
  setPolicy,
  setWebsite,
  getAccountDetails,
  updatingAccountDetails,
  isLoading,
  includeAssets,
  setIncludeAssets
}) => {
  
  const classes = useStyles();
 
  const handleIncludeAssetsChange = e => {
      setIncludeAssets(e.target.checked);
   
  };


  const [displayColorPicker, setDisplayColorPickerVisibility] = useState({
    primary: false,
    primaryContrast: false,
    secondary: false
  });
  const [logoFiles, setLogoFiles] = useState([]);
  // const [favIconFiles, setFavIconFiles] = useState([]);

  const handleClick = type => {
    setDisplayColorPickerVisibility({
      ...displayColorPicker,
      [type]: !displayColorPicker[type]
    });
  };
  const handleClose = type => {
    setDisplayColorPickerVisibility({ ...displayColorPicker, [type]: false });
  };

  const invertColor = hex => {
    let hexColor = hex;

    if (hexColor.indexOf("#") === 0) {
      hexColor = hexColor.slice(1);
    }

    if (hexColor.length === 3) {
      hexColor =
        hexColor[0] +
        hexColor[0] +
        hexColor[1] +
        hexColor[1] +
        hexColor[2] +
        hexColor[2];
    }

    if (hexColor.length !== 6) {
      throw new Error("Invalid HEX color.");
    }

    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);

    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  };

  const handleChangeComplete = (e, type) => {
    if (type === "primary") {
      // Setting this color as the contrast to the primary (Main).
      setTheme("primaryContrast", invertColor(e.hex, true));
    }
    setTheme(type, e.hex);
  };

  const LogoDropzone = () => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: acceptedFiles => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          handleMainLogo(binaryStr);
        };

        setLogoFiles(
          acceptedFiles.map(file => {
            reader.readAsDataURL(file);
            return Object.assign(file, {
              preview: URL.createObjectURL(file)
            });
          })
        );
      }
    });

    return (
      <div className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className={classes.dropZoneDragArea}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
            >
              Choose a file <CloudUploadIcon className={classes.rightIcon} />
            </Button>
            <p nowrap="true">or drag it in here</p>
            <Typography
              color="textSecondary"
              align="center"
              className={classes.dropZoneNotes}
            >
              (Only *.png and *.jpeg images will be accepted with a max height
              of 60px)
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const logoThumb = logoFiles.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // TODO: Add appropriate alt tag
          alt=""
        />
      </div>
    </div>
  ));

  const styles = reactCSS({
    default: {
      color: {
        width: "50px",
        height: "50px",
        borderRadius: "2px"
      },
      swatch: {
        cursor: "pointer",
        width: "50px",
        padding: "0px",
        height: "50px"
      },
      popover: {
        position: "absolute",
        zIndex: "2"
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px"
      }
    }
  });

  useEffect(
    () => () => {
      logoFiles.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [logoFiles]
  );

  const ColorPicker = props => (
    <div className={classes.colorCardWrapper}>
      <Card
        className={classes.colorCard}
        onClick={() => handleClick(props.type)}
      >
        <CardContent className={classes.colorCardContent}>
          <div
            style={{
              ...styles.color,
              backgroundColor: theme[props.type]
            }}
          />
        </CardContent>
      </Card>
      {displayColorPicker[props.type] ? (
        <div style={styles.popover}>
          <div
            style={styles.cover}
            onClick={() => handleClose(props.type)}
            onKeyPress={() => handleClose(props.type)}
            role="button"
            tabIndex="0"
          />
          <SketchPicker
            color={theme[props.type]}
            onChangeComplete={e => handleChangeComplete(e, props.type)}
          />
        </div>
      ) : null}
      <Typography align="center">{props.primaryText}</Typography>
      <Typography
        color="textSecondary"
        align="center"
        className={classes.colorCardNotes}
      >
        {props.secondaryText}
      </Typography>
    </div>
  );

  const handleDomain = e => {
    setDomain(e.target.value);
  };

  const handleTerms = e => {
    setTerms(e.target.value);
  };

  const handlePolicy = e => {
    setPolicy(e.target.value);
  };

  const handleWebsite = e => {
    setWebsite(e.target.value);
  };

  const handleMainLogo = e => {
    setMainLogo(e);
    console.log("handle main logo", e);
  };



  // const handleFavIcon = e => {
  //   setFavIcon(e);
  //   console.log("handle favIcon", e);
  // };

  const currentPath = window.location.pathname;
  const pathComponents = currentPath.split("/").filter(Boolean);
  const [country, accountId] = pathComponents;

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4" color="textSecondary" align="center">
                Welcome to the Arken Acquire customisation setup
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoading && <LinearProgress />}
              <Paper className={classes.paper}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  align="center"
                  className={classes.paperTitle}
                >
                  Customise your Arken Acquire Styling and Domain
                </Typography>
                <div className={classes.root}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Logo</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={4}
                      lg={4}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <section className="container">
                          <LogoDropzone />
                          <aside style={thumbsContainer}>{logoThumb}</aside>
                        </section>
                      </div>
                    </Grid>

                    {/* <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Fav Icon</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={4}
                      lg={4}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <section className="container">
                          <FavIconDropzone />
                          <aside style={thumbsContainer}>{favIconThumb}</aside>
                        </section>
                      </div>
                    </Grid> */}

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Colours</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={4}
                      lg={4}
                      container
                      direction="row"
                      justify="space-evenly"
                      alignItems="center"
                      className={classes.sectionContentWrapper}
                    >
                      <Grid item xs={12} xl={6}>
                        <ColorPicker
                          type="primary"
                          primaryText="Main"
                          secondaryText="(Used for buttons, icons, accents, etc)"
                        />
                      </Grid>
                      <Grid item xs={12} xl={6}>
                        <ColorPicker
                          type="secondary"
                          primaryText="Headings"
                          secondaryText="(Used for the main headings)"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Domain</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={4}
                      lg={4}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="domain-name"
                          label="Specify your custom domain name"
                          className={classes.textField}
                          fullWidth
                          value={domain}
                          onChange={handleDomain}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                .arkenhosted.com
                              </InputAdornment>
                            )
                          }}
                          helperText="If you would like to mask this URL please let Arken know"
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Acquire Domain</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={4}
                      lg={4}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="center"
                        >
                          This link can be used to integrate acquire into your
                          website or given out to your clients
                        </Typography>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          align="center"
                        >
                          https://uk.acquire.arken.legal/
                          {`${country}/${accountId}`}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>
                        Terms & Conditions
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={4}
                      lg={4}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="terms-conditions"
                          label="Specify URL for Terms & Conditions"
                          className={classes.textField}
                          fullWidth
                          value={terms}
                          onChange={handleTerms}
                          InputLabelProps={{
                            shrink: true
                          }}
                          helperText="Please include 'http' or 'https' with your company Terms & Conditions URL"
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Privacy Policy</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={4}
                      lg={4}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="privacy-policy"
                          label="Specify URL for Privacy Policy"
                          className={classes.textField}
                          fullWidth
                          value={policy}
                          onChange={handlePolicy}
                          InputLabelProps={{
                            shrink: true
                          }}
                          helperText="Please include 'http' or 'https' with your Privacy Policy URL (if applicable)"
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>
                        Company Website
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={4}
                      lg={4}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="company-website"
                          label="Specify URL for your website"
                          className={classes.textField}
                          fullWidth
                          value={website}
                          onChange={handleWebsite}
                          InputLabelProps={{
                            shrink: true
                          }}
                          helperText="Please include 'http' or 'https' with your company website URL"
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>
                        Sections
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={4}
                      lg={4}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>

                        <FormGroup>
                          <FormControlLabel 
                           
                            label="Include Assets &amp; Liabilities section"
                            control={
                              <Switch 
                                checked={includeAssets} 
                                onChange={handleIncludeAssetsChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                               />
                              }  
                             />
                         
                        </FormGroup>
                        <p className="MuiFormHelperText-root">If this is set to Yes, your clients will have the option to enter details about their assets & liabilities.</p>     
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={classes.sectionContentWrapper}
                    />
                    <Grid
                      item
                      xs={12}
                      className={classes.sectionContentWrapper}
                    />
                  </Grid>
                </div>
              </Paper>
              {isLoading && <LinearProgress />}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

const mapStateToProps = state => ({
  mainLogo: state.mainLogo,
  // favIcon: state.favIcon,
  theme: state.theme,
  domain: state.domain,
  terms: state.terms,
  policy: state.policy,
  website: state.website,
  accountDetails: state.accountDetails,
  isLoading: state.isLoading,
  includeAssets: state.includeAssets
});

const mapDispatchToProps = {
  setMainLogo: actions.setMainLogo,
  // setFavIcon: actions.setFavIcon,
  setTheme: actions.setTheme,
  setDomain: actions.setDomain,
  setTerms: actions.setTerms,
  setPolicy: actions.setPolicy,
  setWebsite: actions.setWebsite,
  getAccountDetails: actions.getAccountDetails,
  updateAccountDetails: actions.updateAccountDetails,
  updatingAccountDetails: actions.updatingAccountDetails,
  setIncludeAssets: actions.setIncludeAssets
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStyle);
