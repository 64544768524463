import { SET_THINKABOUT_HD } from "../actionTypes";

const initialState = "Things to think about";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_THINKABOUT_HD: {
      return action.payload;
    }
    default:
      return state;
  }
};
