import { call, put, takeEvery, fork, select } from "redux-saga/effects";
import {getAccountDetails,postAccountDetails} from "../api/account" ; 
import * as actions from "../reducers/actions";
import * as selectors from "../selectors";


const isEmpty = obj => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};


function* fetchAccountDetails() {

    console.log('version-info : ', process.env.REACT_APP_VERSIONINFO);

    yield put(actions.setIsLoading(true));
    const currentPath = window.location.pathname;
    const pathComponents = currentPath.split("/").filter(Boolean);
    let accountId;
    let country;
    if (pathComponents.length > 1) {
      [country, accountId] = pathComponents;
    
    yield put(actions.setCountry(country));
  
    console.log('Country: ', 
    country, 
    " accountId:", 
    accountId
    );

    const apiConfig = yield select(selectors.getApiConfig);
    const accountDetails = yield call(
      getAccountDetails,      
      accountId,
      apiConfig.apiUrls
    );

    // I'm adding the themeDetails object to the incoming data before pushing this to store state.
    // This will come through in the data but until then I have to add it.
    let preppedAccountDetails = accountDetails;

    if (isEmpty(accountDetails.data.extensionData)) {
      preppedAccountDetails = {
        ...accountDetails,
        data: {
          ...accountDetails.data,
          extensionData: {
            ...accountDetails.data.extensionData,
            accountHashId: accountId,
            dnsInfo: {},
            activatedOn: "",
            deactivatedOn: "",
            companyDetails: {},
            staticContent: {},
            themes: {},
            logos: {}
          }
        }
      };
      // CompanyDetails
      // Company Name
      yield put(actions.updateAccountDetails(preppedAccountDetails));

      yield put(actions.setCompanyName(accountDetails.data.name));
      yield put(actions.setAddress1(preppedAccountDetails.data.address.street));
      yield put(actions.setCounty(accountDetails.data.address.region));
      yield put(actions.setPostcode(accountDetails.data.address.postcode));
      yield put(actions.setTelephone(accountDetails.data.telephone));
      yield put(actions.setEmail(accountDetails.data.email));
    } else {
      // Custom domain, Terms & Conditions and Privacy Policy
      if (
        typeof preppedAccountDetails.data.extensionData.extensionData.DnsInfo
          .customDomainName !== "undefined"
      ) {
        yield put(
          actions.setDomain(
            preppedAccountDetails.data.extensionData.extensionData.DnsInfo
              .customDomainName
          )
        );
      }
      if (
        typeof preppedAccountDetails.data.extensionData.extensionData.DnsInfo
          .companyTermsConditions !== "undefined"
      ) {
        yield put(
          actions.setTerms(
            preppedAccountDetails.data.extensionData.extensionData.DnsInfo
              .companyTermsConditions
          )
        );
      }
      if (
        typeof preppedAccountDetails.data.extensionData.extensionData.DnsInfo
          .companyPrivacyPolicy !== "undefined"
      ) {
        yield put(
          actions.setPolicy(
            preppedAccountDetails.data.extensionData.extensionData.DnsInfo
              .companyPrivacyPolicy
          )
        );
      }
      if (
        typeof preppedAccountDetails.data.extensionData.extensionData.DnsInfo
          .companyWebsite !== "undefined"
      ) {
        yield put(
          actions.setWebsite(
            preppedAccountDetails.data.extensionData.extensionData.DnsInfo
              .companyWebsite
          )
        );
      }
      yield put(
        actions.setCompanyName(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .companyname
        )
      );
      yield put(
        actions.setAddress1(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .address1
        )
      );
      yield put(
        actions.setAddress2(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .address2
        )
      );
      yield put(
        actions.setAddress3(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .address3
        )
      );
      yield put(
        actions.setCounty(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .county
        )
      );
      yield put(
        actions.setPostcode(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .postcode
        )
      );
      yield put(
        actions.setTelephone(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .telephone
        )
      );
      yield put(
        actions.setEmail(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .email
        )
      );

      // Company Style
      if (
        preppedAccountDetails.data.extensionData.extensionData.Themes.primary
      ) {
        yield put(
          actions.setTheme(
            "primary",
            preppedAccountDetails.data.extensionData.extensionData.Themes
              .primary
          )
        );
      }

      if (
        preppedAccountDetails.data.extensionData.extensionData.Themes
          .primaryContrast
      ) {
        yield put(
          actions.setTheme(
            "primaryContrast",
            preppedAccountDetails.data.extensionData.extensionData.Themes
              .primaryContrast
          )
        );
      }

      if (
        preppedAccountDetails.data.extensionData.extensionData.Themes.secondary
      ) {
        yield put(
          actions.setTheme(
            "secondary",
            preppedAccountDetails.data.extensionData.extensionData.Themes
              .secondary
          )
        );
      }
      yield put(
        actions.setBlurb(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .blurb
        )
      );
      yield put(
        actions.setIncludeAssets(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .includeAssets
        )
      );
      yield put(
        actions.setCompletion(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .completion
        )
      );
      yield put(
        actions.setWhatsNextHd(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .whatsnexthd
        )
      );
      yield put(
        actions.setWhatsNext(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .whatsnext
        )
      );
      yield put(
        actions.setThinkAboutHd(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .thinkabouthd
        )
      );
      yield put(
        actions.setThinkAbout(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .thinkabout
        )
      );
      yield put(
        actions.setUpdateInfoHd(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .updateinfohd
        )
      );
      yield put(
        actions.setUpdateInfo(
          preppedAccountDetails.data.extensionData.extensionData.StaticContent
            .updateinfo
        )
      );

      // Logo
      yield put(
        actions.setMainLogo(
          preppedAccountDetails.data.extensionData.extensionData.Logos.mainLogo
        )
      );
      // FavIcon
      yield put(
        actions.setFavIcon(
          preppedAccountDetails.data.extensionData.extensionData.Logos.favIcon
        )
      );
    }
}
  yield put(actions.setIsLoading(false));
}

function* updatingAccountDetailsAsync() {
  try {
    yield put(actions.setIsLoading(true));

    const currentPath = window.location.pathname;
    const pathComponents = currentPath.split("/").filter(Boolean);
    const [country, accountHashId] = pathComponents;
    yield put(actions.setCountry(country));
    const apiConfig = yield select(selectors.getApiConfig);
 
    const updatedExtensionData = {
      accountHashId,
      dnsInfo: {
        customDomainName: yield select(selectors.getDomain),
        companyTermsConditions: yield select(selectors.getTerms),
        companyPrivacyPolicy: yield select(selectors.getPolicy),
        companyWebsite: yield select(selectors.getWebsite)
      },
      activatedOn: "",
      deactivatedOn: "",
      staticContent: {
        companyname: yield select(selectors.getCompanyName),
        address1: yield select(selectors.getAddress1),
        address2: yield select(selectors.getAddress2),
        address3: yield select(selectors.getAddress3),
        county: yield select(selectors.getCounty),
        postcode: yield select(selectors.getPostcode),
        telephone: yield select(selectors.getTelephone),
        email: yield select(selectors.getEmail),
        blurb: yield select(selectors.getBlurb),
        completion: yield select(selectors.getCompletion),
        whatsnexthd: yield select(selectors.getWhatsNextHd),
        whatsnext: yield select(selectors.getWhatsNext),
        thinkabouthd: yield select(selectors.getThinkAboutHd),
        thinkabout: yield select(selectors.getThinkAbout),
        updateinfohd: yield select(selectors.getUpdateInfoHd),
        updateinfo: yield select(selectors.getUpdateInfo),
        includeAssets: yield select(selectors.getIncludeAssets),
      },
      themes: yield select(selectors.getTheme),
      logos: {
        mainLogo: yield select(selectors.getMainLogo),
        favIcon: yield select(selectors.getFavIcon)
      }
    };
    yield call(
      postAccountDetails,
      updatedExtensionData,
      apiConfig.apiUrls
    );
  } catch (error) {
    console.log("updatingAccountDetailsAsync error", error);
  }
  yield put(actions.setIsLoading(false));
}

function* mySaga() {
  yield fork(fetchAccountDetails);
  yield takeEvery("UPDATING_ACCOUNT_DETAILS", updatingAccountDetailsAsync);
}

export default mySaga;
