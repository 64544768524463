//export const SET_LOGOS = 'SET_LOGOS';  // TODO: Setup of logo...
export const SET_MAIN_LOGO = "SET_MAIN_LOGO";
export const SET_FAVICON = "SET_FAVICON";
export const SET_THEME = "SET_THEME";
export const SET_DOMAIN = "SET_DOMAIN";
export const SET_TERMS = "SET_TERMS";
export const SET_POLICY = "SET_POLICY";
export const SET_WEBSITE = "SET_WEBSITE";
export const SET_BLURB = "SET_BLURB";
export const SET_COMPLETION = "SET_COMPLETION";
export const SET_WHATSNEXT_HD = "SET_WHATSNEXT_HD";
export const SET_WHATSNEXT = "SET_WHATSNEXT";
export const SET_THINKABOUT_HD = "SET_THINKABOUT_HD";
export const SET_THINKABOUT = "SET_THINKABOUT";
export const SET_UPDATEINFO_HD = "SET_UPDATEINFO_HD";
export const SET_UPDATEINFO = "SET_UPDATEINFO";
export const SET_COMPANYNAME = "SET_COMPANYNAME";
export const SET_ADDRESS1 = "SET_ADDRESS1";
export const SET_ADDRESS2 = "SET_ADDRESS2";
export const SET_ADDRESS3 = "SET_ADDRESS3";
export const SET_COUNTY = "SET_COUNTY";
export const SET_POSTCODE = "SET_POSTCODE";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_TELEPHONE = "SET_TELEPHONE";
export const SET_EMAIL = "SET_EMAIL";
export const GET_ACCOUNT_DETAILS = "GET_ACCOUNT_DETAILS";
export const UPDATE_ACCOUNT_DETAILS = "UPDATE_ACCOUNT_DETAILS";
export const UPDATING_ACCOUNT_DETAILS = "UPDATING_ACCOUNT_DETAILS";
export const UPDATING_LOGO = "UPDATING_LOGO";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_INCLUDEASSETS = "SET_INCLUDEASSETS";

