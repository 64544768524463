import assoc from 'ramda/src/assoc';
import { SET_THEME } from '../actionTypes';

const initialState = {
  primary: '#6ea7d2',
  primaryContrast: '#000000',
  secondary: '#3F7CB6',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_THEME: {
      return assoc(action.payload.type, action.payload.color, state);
    }
    default:
      return state;
  }
};
