import { SET_ADDRESS1 } from "../actionTypes";

const initialState = "";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ADDRESS1: {
      return action.payload;
    }
    default:
      return state;
  }
};
