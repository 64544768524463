import axios from "axios";

export const getAccountDetails = (accountId, apiUrls) =>
  axios({
    method: "get",
    url: `${apiUrls.account}?hashId=${accountId}`,
  });

export const postAccountDetails = ( data, apiUrls) =>
  axios({
    method: "post",
    url: `${apiUrls.account}/Admin/Provision`,
    data
  });

