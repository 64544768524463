import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import store from "./store";
import { MenuItems } from "./PageIndex";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <div version-info = {process.env.REACT_APP_VERSIONINFO}></div>
    <ThemeProvider theme={theme}>
      <MenuItems store={store} />
    </ThemeProvider>
  </Provider>,
  rootElement
);
