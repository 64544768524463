import { combineReducers } from "redux";
import mainLogo from "./mainLogo";
import favIcon from "./favIcon";
import theme from "./theme";
import domain from "./domain";
import terms from "./terms";
import policy from "./policy";
import website from "./website";
import blurb from "./blurb";
import includeAssets from "./includeAssets";
import completion from "./completion";
import whatsnexthd from "./whatsnexthd";
import whatsnext from "./whatsnext";
import thinkabouthd from "./thinkabouthd";
import thinkabout from "./thinkabout";
import updateinfohd from "./updateinfohd";
import updateinfo from "./updateinfo";
import companyname from "./companyname";
import address1 from "./address1";
import address2 from "./address2";
import address3 from "./address3";
import county from "./county";
import postcode from "./postcode";
import telephone from "./telephone";
import email from "./email";
import country from "./country";
import accountDetails from "./accountDetails";
import isLoading from "./isLoading";

export default combineReducers({
  mainLogo,
  favIcon,
  theme,
  domain,
  terms,
  policy,
  website,
  blurb,
  completion,
  whatsnexthd,
  whatsnext,
  thinkabouthd,
  thinkabout,
  updateinfohd,
  updateinfo,
  companyname,
  address1,
  address2,
  address3,
  county,
  postcode,
  telephone,
  email,
  country,
  accountDetails,
  isLoading,
  includeAssets
});
