import { SET_BLURB } from "../actionTypes";

const initialState =
  "Congratulations on taking the first step to ensuring you, your family and your assets are protected in the future. To give professional advice we need to understand you and your individual circumstances. By giving us some basic information now, we are in a better position to give you great advice when you come to see us.";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BLURB: {
      if (action.payload === "") {
        return initialState;
      } else {
        return action.payload;
      }
    }
    default:
      return state;
  }
};
