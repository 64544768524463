import { SET_COMPANYNAME } from "../actionTypes";

const initialState = "";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANYNAME: {
      return action.payload;
    }
    default:
      return state;
  }
};
