import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as actions from "./reducers/actions";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1
  },
  colorCardWrapper: {
    margin: "0 10px 10px 10px",
    border: "1px dotted #ddd",
    textAlign: "center",
    padding: "10px"
  },
  colorCard: {
    cursor: "pointer",
    width: "50px",
    padding: "0px",
    height: "50px",
    display: "inline-block"
  },
  colorCardContent: {
    width: "50px",
    height: "50px",
    margin: 0,
    padding: 0
  },
  colorCardNotes: {
    fontSize: "12px"
  },
  companyLogoContiner: {
    flexGrow: 1,
    display: "flex"
  },
  companyLogo: {
    height: "30px"
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#fff",
    color: "#636c72"
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: "#636c72"
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  paperTitle: {
    marginBottom: "30px"
  },
  fixedHeight: {
    height: 240
  },
  sectionTitleWrapper: {
    marginTop: "20px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
      marginBottom: "20px"
    }
  },
  sectionTitle: {
    padding: "10px",
    textAlign: "center",
    height: "100%",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#636c72",
    [theme.breakpoints.up("sm")]: {
      borderRight: "4px solid #dad3d3",
      textAlign: "right"
    }
  },
  sectionContentWrapper: {
    marginBottom: "20px",
    textAlign: "center"
  },
  sectionContent: {
    [theme.breakpoints.up("sm")]: {
      padding: "5px"
    }
  },
  dropZoneNotes: {
    fontSize: "12px"
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  setupNowButton: {
    width: "120px"
  },
  progress: {
    color: "#fff"
  }
}));

const CompanyDetails = ({
  companyname,
  address1,
  address2,
  address3,
  county,
  postcode,
  telephone,
  email,
  setCompanyName,
  setAddress1,
  setAddress2,
  setAddress3,
  setCounty,
  setPostcode,
  setTelephone,
  setEmail,
  getAccountDetails,
  updatingAccountDetails,
  isLoading
}) => {
  const classes = useStyles();

  const handleCompanyName = e => {
    setCompanyName(e.target.value);
  };

  const handleAddress1 = e => {
    setAddress1(e.target.value);
  };

  const handleAddress2 = e => {
    setAddress2(e.target.value);
  };

  const handleAddress3 = e => {
    setAddress3(e.target.value);
  };

  const handleCounty = e => {
    setCounty(e.target.value);
  };

  const handlePostcode = e => {
    setPostcode(e.target.value);
  };

  const handleTelephone = e => {
    setTelephone(e.target.value);
  };

  const handleEmail = e => {
    setEmail(e.target.value);
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4" color="textSecondary" align="center">
                Welcome to the Arken Acquire customisation setup
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoading && <LinearProgress />}
              <Paper className={classes.paper}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  align="center"
                  className={classes.paperTitle}
                >
                  Customise your Arken Acquire Company Details
                </Typography>
                <div className={classes.root}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Name</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={10}
                      lg={10}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="company-name"
                          label="Specify your company name"
                          className={classes.textField}
                          fullWidth
                          value={companyname}
                          onChange={handleCompanyName}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                          helperText="If you would like to use a different company name for Acquire"
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Address</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={10}
                      lg={10}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="company-address1"
                          className={classes.textField}
                          fullWidth
                          value={address1}
                          onChange={handleAddress1}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                          helperText="Address Line 1"
                        />
                        <TextField
                          id="company-address2"
                          className={classes.textField}
                          fullWidth
                          value={address2}
                          onChange={handleAddress2}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                          helperText="Address Line 2"
                        />
                        <TextField
                          id="company-address3"
                          className={classes.textField}
                          fullWidth
                          value={address3}
                          onChange={handleAddress3}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                          helperText="Address Line 3"
                        />
                        <TextField
                          id="company-county"
                          className={classes.textField}
                          fullWidth
                          value={county}
                          onChange={handleCounty}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                          helperText="County"
                        />
                        <TextField
                          id="company-postcode"
                          className={classes.textField}
                          fullWidth
                          value={postcode}
                          onChange={handlePostcode}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                          helperText="PostCode"
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Telephone</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={10}
                      lg={10}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="company-telephone"
                          label=""
                          className={classes.textField}
                          fullWidth
                          value={telephone}
                          onChange={handleTelephone}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                          helperText="If you would like to use a different company telephone number for Acquire"
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Email</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={10}
                      lg={10}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="company-email"
                          label=""
                          className={classes.textField}
                          fullWidth
                          value={email}
                          onChange={handleEmail}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                          helperText="If you would like to use a different company email for Acquire"
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={classes.sectionContentWrapper}
                    ></Grid>
                  </Grid>
                </div>
              </Paper>
              {isLoading && <LinearProgress />}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

const mapStateToProps = state => ({
  companyname: state.companyname,
  address1: state.address1,
  address2: state.address2,
  address3: state.address3,
  county: state.county,
  postcode: state.postcode,
  telephone: state.telephone,
  email: state.email,
  accountDetails: state.accountDetails,
  isLoading: state.isLoading
});

const mapDispatchToProps = {
  setCompanyName: actions.setCompanyName,
  setAddress1: actions.setAddress1,
  setAddress2: actions.setAddress2,
  setAddress3: actions.setAddress3,
  setPostcode: actions.setPostcode,
  setCounty: actions.setCounty,
  setTelephone: actions.setTelephone,
  setEmail: actions.setEmail,
  getAccountDetails: actions.getAccountDetails,
  updateAccountDetails: actions.updateAccountDetails,
  updatingAccountDetails: actions.updatingAccountDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDetails);
