import { SET_THINKABOUT } from "../actionTypes";

const initialState =
  "There will be some important matters to discuss that you will need to make decisions on. In preparation can you think about:\n\n Who you would like to be the executor of your Will. This is an important role as they are the person or organisation that will carry out the wishes in your will.\n\n - How would you like your estate to be distributed. The name and address of the beneficiaries who will receive your assets?\n - Details of your funeral arrangements";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_THINKABOUT: {
      return action.payload;
    }
    default:
      return state;
  }
};
