import { SET_UPDATEINFO } from "../actionTypes";

const initialState =
  "If you already have an appointment with us we ask that all information is entered, no later than, the day before your appointment. This just gives us some time to review and prepare for your appointment.";

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_UPDATEINFO: {
      return action.payload;
    }
    default:
      return state;
  }
};
