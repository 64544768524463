import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as actions from "./reducers/actions";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1
  },
  colorCardWrapper: {
    margin: "0 10px 10px 10px",
    border: "1px dotted #ddd",
    textAlign: "center",
    padding: "10px"
  },
  colorCard: {
    cursor: "pointer",
    width: "50px",
    padding: "0px",
    height: "50px",
    display: "inline-block"
  },
  colorCardContent: {
    width: "50px",
    height: "50px",
    margin: 0,
    padding: 0
  },
  colorCardNotes: {
    fontSize: "12px"
  },
  companyLogoContiner: {
    flexGrow: 1,
    display: "flex"
  },
  companyLogo: {
    height: "30px"
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#fff",
    color: "#636c72"
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: "#636c72"
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  paperTitle: {
    marginBottom: "30px"
  },
  fixedHeight: {
    height: 240
  },
  sectionTitleWrapper: {
    marginTop: "20px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
      marginBottom: "20px"
    }
  },
  sectionTitle: {
    padding: "10px",
    textAlign: "center",
    height: "100%",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#636c72",
    [theme.breakpoints.up("sm")]: {
      borderRight: "4px solid #dad3d3",
      textAlign: "right"
    }
  },
  sectionContentWrapper: {
    marginBottom: "20px",
    textAlign: "center"
  },
  sectionContent: {
    [theme.breakpoints.up("sm")]: {
      padding: "10px"
    },
    textAlign: "left"
  },
  dropZoneNotes: {
    fontSize: "12px"
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  textFieldBlurb: {
    fontWeight: 100
  },
  textFieldCompletion: {
    fontWeight: 100
  },
  textFieldWhatsNext: {
    fontWeight: 100
  },
  textFieldThinkAbout: {
    fontWeight: 100
  },
  textFieldUpdateInfo: {
    fontWeight: 100
  },
  setupNowButton: {
    width: "120px"
  },
  progress: {
    color: "#fff"
  }
}));

const Blurb = ({
  blurb,
  completion,
  whatsnexthd,
  whatsnext,
  thinkabouthd,
  thinkabout,
  updateinfohd,
  updateinfo,
  setBlurb,
  setCompletion,
  setWhatsNextHd,
  setWhatsNext,
  setThinkAboutHd,
  setThinkAbout,
  setUpdateInfoHd,
  setUpdateInfo,
  getAccountDetails,
  updatingAccountDetails,
  isLoading
}) => {
  const classes = useStyles();

  const handleBlurb = e => {
    setBlurb(e.target.value);
  };

  const handleCompletion = e => {
    setCompletion(e.target.value);
  };

  const handleWhatsNextHd = e => {
    setWhatsNextHd(e.target.value);
  };

  const handleWhatsNext = e => {
    setWhatsNext(e.target.value);
  };

  const handleThinkAboutHd = e => {
    setThinkAboutHd(e.target.value);
  };

  const handleThinkAbout = e => {
    setThinkAbout(e.target.value);
  };

  const handleUpdateInfoHd = e => {
    setUpdateInfoHd(e.target.value);
  };

  const handleUpdateInfo = e => {
    setUpdateInfo(e.target.value);
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4" color="textSecondary" align="center">
                Welcome to the Arken Acquire customisation setup
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoading && <LinearProgress />}
              <Paper className={classes.paper}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  align="center"
                  className={classes.paperTitle}
                >
                  Customise your Arken Acquire Text Areas
                </Typography>
                <div className={classes.root}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>Welcome text</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={10}
                      lg={10}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="blurb"
                          label="Any changes to the following text will appear on the homepage"
                          className={`${classes.textField} ${classes.textFieldBlurb}`}
                          fullWidth
                          multiline
                          rowsMax="10"
                          value={blurb}
                          onChange={handleBlurb}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                          helperText=""
                        />
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>
                        Send information text
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={10}
                      lg={10}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="completion"
                          label="Any changes to the following text will appear on the submit page"
                          className={`${classes.textField} ${classes.textFieldCompletion}`}
                          fullWidth
                          multiline
                          rowsMax="10"
                          value={completion}
                          onChange={handleCompletion}
                          helperText=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}>
                        What happens next text
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={10}
                      lg={10}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="whatsnextheading"
                          className={`${classes.textField} ${classes.textFieldWhatsNext}`}
                          fullWidth
                          multiline
                          rowsMax="10"
                          value={whatsnexthd}
                          onChange={handleWhatsNextHd}
                          helperText=""
                        />
                      </div>
                      <div className={classes.sectionContent}>
                        <TextField
                          id="whatsnext"
                          className={`${classes.textField} ${classes.textFieldWhatsNext}`}
                          fullWidth
                          multiline
                          rowsMax="10"
                          value={whatsnext}
                          onChange={handleWhatsNext}
                          helperText=""
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}></div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={10}
                      lg={10}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="thinkaboutheading"
                          className={`${classes.textField} ${classes.textFieldThinkAbout}`}
                          fullWidth
                          multiline
                          rowsMax="10"
                          value={thinkabouthd}
                          onChange={handleThinkAboutHd}
                          helperText=""
                        />
                      </div>
                      <div className={classes.sectionContent}>
                        <TextField
                          id="thinkabout"
                          className={`${classes.textField} ${classes.textFieldThinkAbout}`}
                          fullWidth
                          multiline
                          rowsMax="10"
                          value={thinkabout}
                          onChange={handleThinkAbout}
                          helperText=""
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={2}
                      lg={2}
                      className={classes.sectionTitleWrapper}
                    >
                      <div className={classes.sectionTitle}></div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={10}
                      lg={10}
                      className={classes.sectionContentWrapper}
                    >
                      <div className={classes.sectionContent}>
                        <TextField
                          id="updateinfoheading"
                          className={`${classes.textField} ${classes.textFieldUpdateInfo}`}
                          fullWidth
                          multiline
                          rowsMax="10"
                          value={updateinfohd}
                          onChange={handleUpdateInfoHd}
                          helperText=""
                        />
                      </div>
                      <div className={classes.sectionContent}>
                        <TextField
                          id="updateinfo"
                          label="Any changes to the following text will appear on the what happens next page"
                          className={`${classes.textField} ${classes.textFieldUpdateInfo}`}
                          fullWidth
                          multiline
                          rowsMax="10"
                          value={updateinfo}
                          onChange={handleUpdateInfo}
                          helperText=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{}}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.sectionContentWrapper}
                    ></Grid>
                  </Grid>
                </div>
              </Paper>
              {isLoading && <LinearProgress />}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

const mapStateToProps = state => ({
  blurb: state.blurb,
  completion: state.completion,
  whatsnexthd: state.whatsnexthd,
  whatsnext: state.whatsnext,
  thinkabouthd: state.thinkabouthd,
  thinkabout: state.thinkabout,
  updateinfohd: state.updateinfohd,
  updateinfo: state.updateinfo,
  accountDetails: state.accountDetails,
  isLoading: state.isLoading
});

const mapDispatchToProps = {
  setBlurb: actions.setBlurb,
  setCompletion: actions.setCompletion,
  setWhatsNextHd: actions.setWhatsNextHd,
  setWhatsNext: actions.setWhatsNext,
  setThinkAboutHd: actions.setThinkAboutHd,
  setThinkAbout: actions.setThinkAbout,
  setUpdateInfoHd: actions.setUpdateInfoHd,
  setUpdateInfo: actions.setUpdateInfo,
  getAccountDetails: actions.getAccountDetails,
  updateAccountDetails: actions.updateAccountDetails,
  updatingAccountDetails: actions.updatingAccountDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Blurb);
